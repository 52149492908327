<!-- eslint-disable no-unused-vars -->
<template>
  <b-card title="" class="text-left">
    <b-form>
      <!-- Projeto -->
      <b-row class="my-1 m-2 pl-2 pr-2 text-left d-flex justify-content-center">
        <b-col col xs="12" sm="12" md="6" lg="6">
          <b-form-group>
            <label
              for="projectSelect"
              class="text-center font-weight-bold text-primary"
            >
              Projeto :
            </label>
            <b-form-select
              id="projectSelect"
              v-model="item.project_name"
              :options="projects"
              value-field="id"
              text-field="name"
              placeholder="Selecione um projeto"
            ></b-form-select>
          </b-form-group>
        </b-col>
      </b-row>

      <!-- Valor -->
      <b-row class="my-1 m-2 pl-2 pr-2 text-left d-flex justify-content-center">
        <b-col col xs="12" sm="12" md="6" lg="6">
          <b-form-group>
            <label for="value" class="text-center font-weight-bold text-primary"
              >Valor :</label
            >

            <b-input-group append="R$">
              <BFormInput
                id="value"
                type="text"
                v-model="item.value"
                v-money="moneyMask"
              ></BFormInput>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>

      <!-- Data Refrencia -->

      <b-row class="my-1 m-2 pl-2 pr-2 text-left d-flex justify-content-center">
        <b-col col xs="12" sm="12" md="6" lg="6">
          <b-form-group>
            <label for="date" class="font-weight-bold text-primary"
              >Data de Referência :</label
            >
            <BFormInput
              id="date"
              type="date"
              v-model="item.reference_date"
            ></BFormInput>
          </b-form-group>
        </b-col>
      </b-row>
      <!-- Data Transação -->

      <b-row class="my-1 m-2 pl-2 pr-2 text-left d-flex justify-content-center">
        <b-col col xs="12" sm="12" md="6" lg="6">
          <b-form-group>
            <label for="date" class="font-weight-bold text-primary"
              >Data da Transação :</label
            >
            <BFormInput
              id="date"
              type="date"
              v-model="item.transaction_date"
            ></BFormInput>
          </b-form-group>
        </b-col>
      </b-row>

      <!-- Status -->
      <b-row class="my-1 m-2 pl-2 pr-2 text-left d-flex justify-content-center">
        <b-col col xs="12" sm="12" md="6" lg="6">
          <b-form-group>
            <label for="status" class="font-weight-bold text-primary"
              >Status :</label
            >
            <b-form-select
              id="status"
              v-model="item.status"
              :options="statusOptions"
              placeholder="Selecione o status"
            ></b-form-select>
          </b-form-group>
        </b-col>
      </b-row>

      <!-- Parcelas -->
      <b-row class="my-1 m-2 pl-2 pr-2 text-left d-flex justify-content-center">
        <b-col col xs="12" sm="12" md="6" lg="6">
          <b-form-group>
            <label for="status" class="font-weight-bold text-primary"
              >Parcelas:</label
            >
           <BFormInput
              disabled
              id="installments"
              type="text"
              v-model="item.installments"
            ></BFormInput>
          </b-form-group>
        </b-col>
      </b-row>

      <!-- Descrição -->

      <b-row class="my-1 m-2 pl-2 pr-2 text-left d-flex justify-content-center">
        <b-col col xs="12" sm="12" md="6" lg="6">
          <b-form-group>
            <label for="description" class="font-weight-bold text-primary"
              >Descrição :</label
            >
            <BFormTextarea
              id="description"
              type="textarea"
              v-model="item.description"
            ></BFormTextarea>
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>

    <div :style="{ gap: '20px' }" class="justify-center">
      <BButton variant="outline" @click="$router.push('/lancamento-de-receita')"
        >Voltar</BButton
      >
      <BButton variant="danger" @click="deleteItem">Excluir</BButton>
      <BButton @click="updateItem">Salvar</BButton>
    </div>
  </b-card>
</template>

<script>
import {
  BCard,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BCol,
  BRow,
  BFormSelect,
  BInputGroup,
} from 'bootstrap-vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { VBTooltip } from 'bootstrap-vue';

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BCard,
    BFormInput,
    BCol,
    BRow,
    BForm,
    BFormGroup,
    BFormTextarea,
    BFormSelect,
    BButton,
    BInputGroup,
  },
  data: () => ({
    haveInstallments: false,
    item: { project_name: '', transaction_date: '01/01/2023' },
    installments: [],
    projects: [],
    date: '21/08/2023',
    statusOptions: ['previsto', 'realizado'],
    moneyMask: {
      prefix: 'R$ ',
      suffix: '',
      thousands: '.',
      decimal: ',',
      precision: 2,
      masked: false,
    },
  }),

  created() {
    this.getItem();
    this.getProjects();
  },

  computed: {},

  methods: {
    async getItem() {
      this.$store
        .dispatch('getRevenue', {
          id: this.$route.params.id,
          workspace_id: this.$store.getters.currentWorkspace.id,
        })
        .then((resp) => {
          const item = {
            ...resp,
            value: this.numberToMonetary(resp.value),
            reference_date: this.dateFormatter(
              resp.reference_date,
              'yyyy-MM-dd'
            ),
            transaction_date: this.dateFormatter(
              resp.transaction_date,
              'yyyy-MM-dd'
            ),
            created_at: this.dateFormatter(resp.created_at),
            updated_at: this.dateFormatter(resp.updated_at),
            installments: resp.installments.length > 0 ? 'Sim, ' + resp.installments.length + ' parcelas de ' + 'R$ ' + this.numberToMonetary(resp.installments[0].value): 'Não',
          };
          this.item = item;
          this.haveInstallments = item.installments;

          this.installments = resp.installments;
          console.table(this.installments)
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async deleteItem() {
      this.$store
        .dispatch('destroyRevenue', this.$route.params.id)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Receita excluida com sucesso.',
              icon: 'SuccessIcon',
              variant: 'success',
            },
          });
          this.$router.push('/lancamento-de-receita');
        })
        .catch((error) => {
          console.log(error);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Erro ao excluir receita.',
              icon: 'XIcon',
              variant: 'danger',
            },
          });
        });
    },

    async getProjects() {
      this.$store
        .dispatch('_getProjects', {
          id: this.$route.params.id,
          workspace_id: this.$store.getters.currentWorkspace.id,
        })
        .then((resp) => {
          resp.data.map((e) => {
            this.projects.push(e.project_name);
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async updateItem() {
      let value = this.monetaryToNumber(this.item.value);
      let newInstallmentLength = this.installments.length;

      let revenueValue = parseInt(value / newInstallmentLength);

      const newInstallmentValue = revenueValue;
      const newInstallment = [];   

      console.log({newInstallmentValue});

      if(this.installments.length > 0){
        for (let i = 1; i <= newInstallmentLength; i++) {
          newInstallment.push({
            value: newInstallmentValue,
            transaction_date: this.item.transaction_date,
          });
        }
       }

      this.$store
        .dispatch('updateRevenue', {
          installments: newInstallment,
          value: value,
          reference_date: this.item.reference_date,
          transaction_date: this.item.transaction_date,
          status: this.item.status,
          description: this.item.description,
          id: this.item.id,
        })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Receita alterada com sucesso.',
              icon: 'SuccessIcon',
              variant: 'success',
            },
          });
          this.$router.push({
            name: 'lancamento-de-receita',
          });
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Erro ao alterar receita.',
              icon: 'XIcon',
              variant: 'danger',
            },
          });
        });
    },
  },
};
</script>
<style>
.justify-center {
  display: flex;
  justify-content: center;
}
td::before {
  float: none !important;
}
</style>
